import React from 'react'
import styled from 'react-emotion'
import Image from '../components/Image'
import { One, OneThird, Row, RowGroup, Three, Two } from '../components/Grid'
import Button from '../components/Button'
import { CONTRAST_COLOR, FONT_SMALL } from '../constants'
import Caption from '../components/Caption'
import SEO from '../components/SEO'
import { prefillMessage } from '../utils'
import { H1, H2, H4, Section } from '../components/Style'
import Link from '../components/Link'
import ContactForm from '../components/ContactForm'
import ClientsContext from '../components/ClientsContext'
import chunk from 'lodash/chunk'
import orderBy from 'lodash/orderBy'
import Layout from '../components/Layout'

const breadcrumbs = `{
  "@context": "http://schema.org",
  "@type": "BreadcrumbList",
  "itemListElement": [{
    "@type": "ListItem",
    "position": 1,
    "item": {
      "@id": "https://500tech.com/contact",
      "name": "Contact Us"
    }
  }]
}`

const FEATURED_CLIENTS = [
  'IronSource',
  'Outbrain',
  'Wix',
  'Check Point',
  'Technion',
  'Autodesk',
  'Dell',
  'Hewlett-Packard',
  'Microsoft',
  'New York City Department of Planning',
  'Cisco',
  'Intel',
  'Nokia',
  'Siemens',
  'ADP',
  'View The Space',
]

const selectClients = allClients =>
  chunk(
    orderBy(
      allClients.filter(({ name }) => FEATURED_CLIENTS.includes(name)),
      'name',
      'asc'
    ),
    4
  )

export default props => {
  return (
    <Layout>
      <SEO
        path="/"
        title="Professional Front-End Development Agency - 500Tech"
        description="500Tech is a front-end development agency in Israel. We build advanced web applications."
        ld={breadcrumbs}
      />

      <Section>
        <H1 style={{ maxWidth: '100%' }}>
          Top professionals building complex web applications
        </H1>
      </Section>

      <Section>
        <Row>
          <OneThird>
            <H2>
              <Link to="/projects">Development</Link>
            </H2>
            <p>
              We deliver sophisticated, long lasting, production software on
              time. With dozens of production projects under our belt, we have
              vast experience with numerous development methodologies.
            </p>
          </OneThird>

          <OneThird>
            <H2>
              <Link to="/consulting">
                Consulting
              </Link>
            </H2>
            <p>
              Having completed hundreds of successful projects, our consultants
              can share best practices, avoid common pitfalls and help you save
              hundreds of development hours.
            </p>
          </OneThird>

          <OneThird>
            <H2>
              <Link to="/training">Training</Link>
            </H2>
            <p>
              We prepare your development teams to work on large scale projects.
              Our instructors are ready to help with basic setup and guidance
              over the initial development phases.
            </p>
          </OneThird>
        </Row>
      </Section>

      <Section>
        <MainSubtitle>
          Building complex and <nobr>diverse projects</nobr>
        </MainSubtitle>

        <Row scrollable>
          <One>
            <Link to="/projects/phytech" noUnderline innerHighlight>
              <Image path="projects/phytech/phytech-icon.jpg" />
            </Link>

            <Caption>
              <Link to="/projects/phytech" white className="inner-link">
                We helped Phytech build their realtime farm-monitoring
                application
              </Link>{' '}
              with React and migrate iOS and Android apps to React Native
            </Caption>
          </One>

          <One>
            <Link to="/projects/jfrog" noUnderline innerHighlight>
              <Image path="projects/jfrog/jfrog-icon.jpg" />
            </Link>

            <Caption>
              <Link to="/projects/jfrog" white className="inner-link">
                Together with jFrog, we worked to rewrite Artifactory
              </Link>, their flagship product for software management and
              distribution, using AngularJS
            </Caption>
          </One>

          <One>
            <Link to="/projects/proggio" noUnderline innerHighlight>
              <Image path="projects/proggio/proggio-icon.jpg" />
            </Link>

            <Caption>
              <Link to="/projects/proggio" white className="inner-link">
                We've built Proggio's first project management application
              </Link>{' '}
              with project plan visualization and team collaboration from the
              ground up.
            </Caption>
          </One>

          <One>
            <Link to="/projects/unpakt" noUnderline innerHighlight>
              <Image path="projects/unpakt/unpakt-icon.jpg" />
            </Link>

            <Caption>
              <Link to="/projects/unpakt" white className="inner-link">
                We helped Unpakt completely rebuild their move booking flow
              </Link>{' '}
              as a single-page application using AngularJS
            </Caption>
          </One>
        </Row>

        <Row>
          <Three>
            <p>
              Our team specializes in complex web and mobile applications and have built projects that smoothly handle
              thousands of live updates per minute, display complicated charts,
              custom user interfaces, geographical and medical data and
              maintaining compatibility with a plethora of devices.
            </p>
            <Button
              onClick={() =>
                prefillMessage("Hi, I'd like to get a quote for a new project.")
              }>
              Contact us
            </Button>
          </Three>

          <One>
            <div className="smaller">
              <H4>Code as a product</H4>

              <p>
                We guarantee a production, high quality application, on time and
                within budget.
              </p>
            </div>
          </One>
        </Row>
      </Section>

      <Section>
        <MainSubtitle>Working with many industries</MainSubtitle>
        <ClientsContext.Consumer>
          {allClients => (
            <RowGroup>
              {selectClients(allClients).map((row, index) => (
                <Row key={index}>
                  {row.map(client => (
                    <One key={client.name}>
                      <ClientName>{client.name}</ClientName>
                      <Description>{client.description}</Description>
                    </One>
                  ))}
                </Row>
              ))}
            </RowGroup>
          )}
        </ClientsContext.Consumer>
        See over 225 companies we've worked with sorted by industry on our{' '}
        <Link to="/clients">clients page</Link>
      </Section>

      <Section>
        <MainSubtitle>Spreading our knowledge</MainSubtitle>

        <Row>
          <Three>
            <Image path="share-knowledge.jpg" />
          </Three>

          <One>
            <p>
              Teaching is a part of our company's DNA: we do internal lectures
              and workshops, speak at meetups and conferences, write books and
              blog posts.
            </p>

            <Button
              onClick={() =>
                prefillMessage(
                  "Hi, I'd like to learn more about your courses and workshops."
                )
              }>
              Learn more
            </Button>
          </One>
        </Row>

        <Row className="smaller">
          <OneThird>
            <Link to="/training" noUnderline innerHighlight>
              <Image path="training/boris-1.jpg" />
            </Link>

            <Caption>
              <H4>
                <Link to="/training" white>
                  Courses and workshops
                </Link>
              </H4>

              <p>
                Over the past half decade we have taught front-end to over a
                thousand developers from all around the world.
              </p>
            </Caption>
          </OneThird>

          <OneThird>
            <Row style={{ marginBottom: 10 }}>
              <Two mobile style={{ paddingRight: 5 }}>
                <Link to="https://youtu.be/jW6wriY2e4Y">
                  <Image path="home/talks-1.jpg" />
                </Link>
              </Two>
              <Two mobile style={{ paddingLeft: 5 }}>
                <Link to="https://youtu.be/00ys34cvudY">
                  <Image path="home/talks-2.jpg" />
                </Link>
              </Two>
            </Row>

            <Row style={{ marginBottom: 0 }}>
              <OneThird mobile style={{ marginBottom: 0, paddingRight: 0 }}>
                <Link to="https://youtu.be/00ys34cvudY">
                  <Image path="home/talks-3.jpg" />
                </Link>
              </OneThird>
              <OneThird mobile style={{ marginBottom: 0 }}>
                <Link to="https://youtu.be/8Mt2BSXZGcY">
                  <Image path="home/talks-4.jpg" />
                </Link>
              </OneThird>
              <OneThird mobile style={{ marginBottom: 0, paddingRight: 0 }}>
                <Link to="https://youtu.be/nbzniZ_4Y1Y">
                  <Image path="home/talks-5.jpg" />
                </Link>
              </OneThird>
            </Row>

            <Caption>
              <H4>
                <Link to="/talks" white>
                  Tech talks
                </Link>
              </H4>
              <p>
                We share our experience, insights, and ideas at big conferences
                and small local meetups in many countries.
              </p>
            </Caption>
          </OneThird>

          <OneThird>
            <Row style={{ marginBottom: 0 }}>
              <OneThird style={{ flex: 0.770992366, marginBottom: 0 }}>
                <Link to="http://redux-book.com">
                  <Image path="home/book-redux@2x.png" />
                </Link>
              </OneThird>

              <OneThird
                style={{ flex: 0.770992366, marginBottom: 0, padding: 0 }}>
                <Link to="https://leanpub.com/angular-forms">
                  <Image path="home/book-ng-reactive-forms@2x.png" />
                </Link>
              </OneThird>

              <OneThird style={{ flex: 0.816793893, marginBottom: 0 }}>
                <Link to="https://www.packtpub.com/web-development/angular-2-components">
                  <Image path="home/book-ng-components@2x.png" />
                </Link>
              </OneThird>
            </Row>

            <Caption>
              <p>
                We write books on the technologies we use in our work to share
                the best practices:
              </p>

              <ul>
                <li>
                  <Link to="http://redux-book.com" white>
                    The Complete Redux Book
                  </Link>
                </li>
                <li>
                  <Link to="https://leanpub.com/angular-forms" white>
                    Angular Reactive Forms
                  </Link>
                </li>
                <li>
                  <Link
                    white
                    to="https://www.packtpub.com/web-development/angular-2-components">
                    Angular 2 Components
                  </Link>
                </li>
              </ul>
            </Caption>
          </OneThird>
        </Row>
      </Section>
      <ContactForm />
    </Layout>
  )
}

const MainSubtitle = styled(H2)`
  font-weight: 300;
  max-width: 720px;
  margin: 0 0 0.8em 0;
  font-size: 1.8em;
  line-height: 1.3em;
`

const Description = styled.div`
  font: ${FONT_SMALL};
  line-height: 1.5;
`

const ClientName = styled.div`
  width: 90%;
  color: ${CONTRAST_COLOR};
  line-height: 1.4;
`
